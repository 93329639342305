import React from "react";
import lawson from "../../../assets/images/lawson.jpg";
import matt from "../../../assets/images/matt.jpg";
import daniel from "../../../assets/images/daniel.jpg";
import ashley from "../../../assets/images/ashley.jpg";

export default function Team() {
  const teamMembers = [
    {
      image: lawson,
      name: "Neill Lawson-Smith",
      position: "Founder & Managing Director",
    },
    {
      image: matt,
      position: "Operations Director",
      name: "Matt Grantham",
    },
    {
      image: daniel,
      name: "Daniel Hadland",
      position: "Technology & Innovation Director",
    },
    {
      image: ashley,
      position: "Finance Director",
      name: "Ashley Barker",
    },
  ];

  return <></>;
  // <section id="team" className="section">
  //   <div className="container">
  //     <div className="text-left mb-5 md:mb-10 md:pt-7">
  //       <p className="text-xl text-black mb-4">Our Team</p>
  //       <p className="text-2xl font-bold md:text-4xl">
  //         Meet TPS's Board of Directors
  //       </p>
  //     </div>

  //     <div className="flex flex-wrap md:flex-nowrap md:flex-row md:space-x-5">

  //       {teamMembers.map((member, index) => (
  //         <div key={index} className=" md:w-1/4 m-3 w-5/12">
  //           <img
  //             alt={member.name}
  //             src={member.image}
  //           />

  //           <div className="space-y-3 text-left my-5">
  //             <p className=" text-xl md:text-2xl font-bold">{member.name}</p>
  //             <p className="text-blue-600 text-sm md:text-lg ">
  //               {member.position}
  //             </p>
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // </section>
}
